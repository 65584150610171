// Copyright (C) 2024 Skylark Drones

export class RealtimeCommunicationClient {
  rtcClient = null

  constructor() {
    if (new.target === RealtimeCommunicationClient) {
      throw new TypeError('Cannot construct base RTC client class directly')
    }
  }

  connect() {
    throw new Error('connect method not implemented by subclass')
  }

  disconnect() {
    throw new Error('disconnect method not implemented by subclass')
  }

  getChannel(channelName) {
    throw new Error('getChannel method not implemented by subclass')
  }

  subscribe(channelName, eventName, listenerCb) {
    throw new Error('subscribe method not implemented by subclass')
  }

  unsubscribe(channelName, eventName) {
    throw new Error('unsubscribe method not implemented by subclass')
  }

  send(channelName, eventName, message) {
    throw new Error('send method not implemented by subclass')
  }
}
