// Copyright (C) 2018-2024 Skylark Drones

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import vuetify from './vuetify'
import Hotjar from 'vue-hotjar'
import router from './router'
import moment from 'moment'
import dayjs from 'dayjs'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import { omit } from 'lodash'

import App from './App'
import rbac from './utils/common/rbac'
import cRbac from './utils/common/cRbac'
import metaRbac from '@/utils/common/metaRbac'
import ConverterPlugin from '@/utils/common/UnitConvertersPlugin'
import store from './store'
import apiInstance from '@/config'
import { HOTJAR_APP_ID, HOTJAR_ENABLED } from '@/constants/service'
import * as Sentry from '@sentry/vue'
import analyticsPlugin from '@/utils/analytics'
import { setFavicon, setAppTitle } from '@/utils/common/metadata'

import utc from 'dayjs/plugin/utc'
import { getBrand } from './utils/common'
import { dmoBrand, whiteLabelBrandConfig } from './constants/domain'
import featureAccessControl from './utils/common/featureAccessControl'
import PusherClient from './utils/realtime_communication/PusherService'

dayjs.extend(utc)
Vue.prototype.$dayjs = dayjs
Vue.prototype.$moment = moment
Vue.prototype.$featureAccessControl = featureAccessControl
Vue.prototype.$rtcClient = PusherClient

Vue.use(rbac)
Vue.use(cRbac)
Vue.use(metaRbac)

Vue.use(ConverterPlugin)

Vue.use(Hotjar, {
  id: HOTJAR_APP_ID,
  isProduction: HOTJAR_ENABLED
})

Vue.use(analyticsPlugin)

Vue.config.productionTip = false

/* Vue.config.errorHandler = function (err, vm, info) {
  console.log('[Global Error Handler]: Error in ' + info + ': ' + err)
} */

apiInstance.setupInterceptors()

Vue.prototype.$analytics.initializeTracking()

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN_URL,
  environment: process.env.VUE_APP_ENV,
  release: process.env.VUE_APP_RELEASE_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        'localhost',
        '127.0.0.1',
        'app.dronemissionops.com',
        'staging.dronemissionops.com',
        /^\//
      ]
    }),
    new Sentry.Replay({
      maskAllText: true
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: parseFloat(
    process.env.VUE_APP_SENTRY_SESSION_SAMPLE_RATE
  ),
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: parseFloat(
    process.env.VUE_APP_SENTRY_ON_ERROR_SAMPLE_RATE
  ),
  // whether SDK should call Vue's original logError function as well.
  logErrors: true,
  // this feature provides you with spans in your transactions that represent the component life cycle events and durations
  trackComponents: true
})

// Setting the application style according to the domain
let config = whiteLabelBrandConfig[dmoBrand]

const brand = getBrand()
if (brand) {
  config = whiteLabelBrandConfig[brand]
}

store.commit('brand/setConfig', omit(config, ['theme']))
vuetify.framework.theme.themes.light = {
  ...vuetify.framework.theme.themes.light,
  ...config.theme,
  'pro-bg': 'linear-gradient(135deg, #f05b28, #531a85 75%, #4a178a)'
}
setAppTitle(store.getters['brand/appName'])
setFavicon(store.getters['brand/favicon'])

apiInstance.publicHttp.defaults.headers.common['X-Brand'] = brand
apiInstance.http.defaults.headers.common['X-Brand'] = brand

export default new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  components: { App },
  template: '<App/>'
})
