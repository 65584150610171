// Copyright (C) 2024 Skylark Drones

export const DISPLAY_PLANS = process.env.VUE_APP_DISPLAY_PLANS === 'true'

export const faqList = [
  {
    key: 'pricing',
    name: 'Pricing',
    qna: [
      {
        question: 'Can I change my plan later?',
        answer:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
      },
      {
        question: 'What are the modes of payment available ?',
        answer:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
      }
    ]
  },
  { key: 'plans', name: 'Plans', qna: [] },
  { key: 'refund', name: 'Refund', qna: [] },
  { key: 'custom', name: 'Custom', qna: [] }
]

export const customPlan = {
  message: 'Unlock Pro Features for 1 Linked Drone',
  secondary_message: 'Recommended for Pro Freelance Pilots',
  summary: [
    'Plan any mission type',
    'Mission splitting and chunking',
    'Data check up to 500 images',
    '5 free livestream sessions',
    'Web portal sync with android'
  ],
  id: 'custom_plan',
  key: 'custom_plan',
  name: 'Enterprise',
  prices: [{ country: 'default', currency: 'USD', value: null }],
  period: 'monthly',
  features: []
}

export const currencySymbol = {
  USD: '$',
  INR: '₹'
}

export const plansKey = {
  noLimit: 'no_limit',
  free: 'free'
}
