<!-- Copyright (C) 2018-2024 Skylark Drones -->
<template>
  <v-app class="app">
    <v-main>
      <sidebar v-if="$route.meta.showSidebar && showSidebarOnCurrentPage" />
      <welcome-wizard
        v-if="showWelcomeWizard"
        @close-dialog="showWelcomeWizard = false"
      />
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <snackbar />
      <company-access-none-dialog />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Sentry from '@sentry/browser'

import { getUser } from '@/services/UserService'
import SideBar from '@/components/common/SideBar'
import Snackbar from '@/components/common/Snackbar'
import { companySelection, getBrand } from '@/utils/common'
import { loadFreeFCL } from '@/utils/common/featureAccessControl.js'

const WelcomeWizard = () => import('@/components/welcomewizard/WelcomeWizard')
const CompanyAccessNoneDialog = () =>
  import('@/components/common/CompanyAccessNoneDialog.vue')

export default {
  name: 'App',

  components: {
    sidebar: SideBar,
    snackbar: Snackbar,
    'welcome-wizard': WelcomeWizard,
    'company-access-none-dialog': CompanyAccessNoneDialog
  },

  data() {
    return {
      showWelcomeWizard: false
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'company',
      'tokenAuthorized',
      'tokenIssuer',
      'idToken',
      'userPersonalSpace',
      'currentActiveOrganizationId',
      'freeFCL'
    ]),
    showSidebarOnCurrentPage() {
      if (
        ['NewsOverview', 'AirspaceAdvisory', 'DroneDataVerification'].includes(
          this.$route.name
        ) &&
        !this.user
      ) {
        return false
      }

      // According to material design, small form factors such as mobile shouldn't
      // show a sidebar. Instead, they show a sidebar on demand (hamburger menu) on
      // mobile, which changes to a navigation rail on tablets and to a full fledged
      // navigation drawer on larger form factors.
      // Todo: Replace this with a more general purpose hiding of sidebar in mobile
      return !(
        ['StreamPage', 'StreamPageOld', 'StreamPrivatePage'].includes(
          this.$route.name
        ) && this.$vuetify.breakpoint.smAndDown
      )
    }
  },

  watch: {
    user: {
      handler: function () {
        if (this.user === null) {
          this.showPrivacyPolicy = false

          if (!this.freeFCL) {
            loadFreeFCL()
          }
          return
        }

        this.showPrivacyPolicy = !this.user.accepted_privacy_policy
        this.showWelcomeWizard = !this.user.seen_welcome_wizard
      }
    }
  },

  created() {
    this.loadUser()
    this.checkForOutdatedUser()
    this.checkForActiveCompany()
    this.loadTokenAuthorized()
    this.loadTokenIssuer()
    this.loadIdToken()

    // Do not load fcl when logging in
    if (!('id_token' in this.$router.currentRoute.query)) {
      loadFreeFCL()
    }
  },

  methods: {
    ...mapMutations([
      'updateUser',
      'setTokenAuthorized',
      'setTokenIssuer',
      'setIdToken',
      'setIsFetchingUser',
      'updateNotificationPayload',
      'setCurrentActiveOrganizationId',
      'updateUserAttribute',
      'setFreeFCL'
    ]),
    ...mapActions(['signOut']),
    loadUser() {
      const user = JSON.parse(localStorage.getItem('user'))

      if (user && this.user === null) {
        // Enriching sentry events with user id
        Sentry.setUser({ id: user.id })

        this.$analytics.setUserIdentifier(user, getBrand())

        // Initialize with stale user data
        this.updateUser(user)

        // Fetch latest user data
        this.fetchUser(user.id)
      }
    },
    loadTokenAuthorized() {
      const isAuthorized = JSON.parse(localStorage.getItem('tokenAuthorized'))
      if (Boolean(isAuthorized) !== this.tokenAuthorized) {
        this.setTokenAuthorized(Boolean(isAuthorized))
      }
    },
    loadTokenIssuer() {
      const tokenIssuer = localStorage.getItem('tokenIssuer')
      if (tokenIssuer && tokenIssuer !== 'null' && this.tokenIssuer === null) {
        this.setTokenIssuer(tokenIssuer)
      }
    },
    loadIdToken() {
      const idToken = localStorage.getItem('idToken')
      if (idToken && idToken !== 'null' && this.idToken === null) {
        this.setIdToken(idToken)
      }
    },
    checkForOutdatedUser() {
      if (!this.user) return
      if (
        !this.user.hasOwnProperty('web_schema_version') ||
        this.user.web_schema_version !== 7
      ) {
        this.signOut()
        this.$router.push({ name: 'HomePage' })
      }
    },
    async fetchUser(userId) {
      try {
        this.setIsFetchingUser(true)

        const resp = await getUser(userId)
        this.updateUserAttribute(resp.data)

        // Set the latest preferences after checking for undefined
        // currentActiveOrganizationId just in case ran before it is set
        if (this.currentActiveOrganizationId) {
          this.setCurrentOrgId(this.currentActiveOrganizationId)
        }
      } catch (error) {
        this.updateNotificationPayload({
          message: 'Unable to fetch user web preferences!',
          code: 400,
          timeout: 5000
        })
      } finally {
        this.setIsFetchingUser(false)
        // Check active company after getting latest user companies
        this.checkForActiveCompany()
      }
    },
    setCurrentOrgId(companyId) {
      this.setCurrentActiveOrganizationId(companyId)
      companySelection.persist()
    },
    checkForActiveCompany() {
      // This stop to check for companyId if user in not loggedId
      if (this.user) {
        let companyId = this.$route.params.companyId

        // TEMP: check for routes not having companyId until all routes are migrated to
        // company routes
        if (companyId) {
          if (
            this.$route.name &&
            !this.user.companies.find(company => company.id === companyId)
          ) {
            this.updateNotificationPayload({
              code: 400,
              message: 'You are no more part of this company',
              timeout: 5000
            })

            companyId = this.userPersonalSpace.id
            this.setCurrentOrgId(companyId)
            this.$router.replace({
              name: this.$route.name,
              params: { companyId }
            })
            return
          }
        } else {
          companyId = companySelection.previousCompanyId
        }

        this.setCurrentOrgId(companyId)
      }
    }
  }
}
</script>

<style scoped>
.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    135deg,
    #dfdfdf 0%,
    #ffffff 100%
  ) !important;
}
.full-height {
  height: 100%;
}

::v-deep .pro-bg {
  background: linear-gradient(135deg, #f05b28, #531a85 75%, #4a178a);
}
</style>
