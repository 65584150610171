<!-- Copyright (C) 2023-2024 Skylark Drones -->
<template>
  <div>
    <v-menu offset-x right max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <org-switcher-button height="64" v-bind="attrs" v-on="on" />
      </template>
      <v-list class="py-0 rounded-lg">
        <template v-if="companies.length > 1">
          <v-list-item dense>
            <v-list-item-title class="text-body-2 text--secondary">
              My Workspaces
            </v-list-item-title>
          </v-list-item>
          <v-divider />
        </template>
        <template v-for="company in companies">
          <v-list-item
            :key="company.id"
            @click="setAsActiveOrganization(company)"
          >
            <v-list-item-icon>
              <v-img
                v-if="company.logo"
                :src="company.logo"
                width="24px"
                height="24px"
                contain
              />
              <v-icon v-else-if="company.personal_space"> mdi-account </v-icon>
              <v-icon v-else>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" data-hj-suppress>
                {{
                  company.personal_space ? 'Personal Workspace' : company.name
                }}
              </v-list-item-title>
              <v-list-item-subtitle style="white-space: normal">
                {{ getCompanySubtitle(company) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="company.personal_space"
                style="white-space: normal"
              >
                Your private space for saving sites & missions.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
                v-if="company.id === currentActiveOrganizationId"
                color="primary"
              >
                mdi-check-circle
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <template v-if="user.invites.length > 0">
          <v-divider />
          <v-list-item
            :to="{
              name: 'UsersDisplay',
              params: { userId: user.id, tabKey: 'invites' }
            }"
          >
            <v-list-item-icon>
              <v-icon>mdi-email-seal</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                {{ user.invites.length }} Workspace Invite
              </v-list-item-title>
              <v-list-item-subtitle> View Invite </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-divider />
        <v-list-item
          :disabled="companies.length > 1"
          @click="showCreateOrganizationDialog = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Create Workspace
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: normal">
              {{
                companies.length > 1
                  ? 'You can only be a member of one workspace at a time.'
                  : `Add your business on ${appNameAbbr}`
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <create-organisation-dialog
      v-if="showCreateOrganizationDialog"
      @close-dialog="showCreateOrganizationDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

import OrganizationMixin from '../organization/OrganizationMixin'
import OrganizationSwitchButton from './OrganizationSwitchButton.vue'
import { companySelection, getIsNoLimitPlan } from '@/utils/common'

const CreateOrganizationDialog = () =>
  import('@/components/organization/CreateOrganizationDialog.vue')

export default {
  name: 'OrganizationSwitcherMenu',

  components: {
    'org-switcher-button': OrganizationSwitchButton,
    'create-organisation-dialog': CreateOrganizationDialog
  },

  mixins: [OrganizationMixin],

  data() {
    return {
      showCreateOrganizationDialog: false
    }
  },

  computed: {
    ...mapGetters('brand', ['appNameAbbr']),
    ...mapGetters(['currentActiveOrganizationId', 'user']),
    companies() {
      return this.user.companies
    }
  },

  methods: {
    ...mapMutations(['setCurrentActiveOrganizationId']),
    async setAsActiveOrganization(company) {
      const companyId = company.id

      if (companyId !== this.currentActiveOrganizationId) {
        try {
          await this.$router.replace({
            name: 'LoggedInHomePage',
            params: { companyId }
          })

          this.setCurrentActiveOrganizationId(companyId)
          companySelection.persist()
          this.$analytics.trackOrganizationSwitch(company.personal_space)
        } catch (error) {
          // Catch the navigation discarded error of above the navigation
        }
      }
    },
    isCompanyHasActivePlan(company) {
      return !isEmpty(company.plan)
    },
    getCompanySubtitle(company) {
      let text = ''
      const isNoLimitPlan = getIsNoLimitPlan(company)

      if (!isNoLimitPlan) {
        text += this.isCompanyHasActivePlan(company)
          ? company.plan.name
          : 'Free'

        text += ' Plan'
      }

      if (!isNoLimitPlan && !company.personal_space) {
        text += ' · '
      }

      if (!company.personal_space) {
        text += this.MEMBER_ROLES[company.role].label
      }

      return text
    }
  }
}
</script>
