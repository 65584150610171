// Copyright (C) 2020-2024 Skylark Drones

import { isEmpty } from 'lodash'
import { domainBrandMap } from '@/constants/domain'
import CompanySelection from './companySelection'

/**
 * Execute a function periodically at specified intervals with an optional max. limit
 * @param {function} func Function that will be executed over a given interval
 * @param {function} validate Result validator to check if poll should be short circuit
 * @param {Number} interval Time interval between polls in milliseconds
 * @param {Number} maxAttempts Max. number of attempts before aborting the poll
 * @param {Object} tracker Polling tracker to allow for cancellation of timeout
 * @returns {Promise<unknown>}
 */
export async function poll(
  func,
  validate,
  interval,
  maxAttempts,
  tracker = null
) {
  let attempts = 0

  const pollExecutor = async (resolve, reject) => {
    const result = await func()
    attempts++

    if (validate(result)) {
      return resolve(result)
    } else if (maxAttempts > 0 && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    } else {
      const poller = setTimeout(pollExecutor, interval, resolve, reject)
      if (tracker !== null) tracker.push(poller)
    }
  }

  return new Promise(pollExecutor)
}

export function isUserLoggedIn() {
  const tokenAuthorized = JSON.parse(localStorage.getItem('tokenAuthorized'))
  const user = JSON.parse(localStorage.getItem('user'))
  return Boolean(tokenAuthorized) && user !== null
}

export function getIsEmailValid(email) {
  // https://github.com/vuelidate/vuelidate/blob/next/packages/validators/src/raw/email.js
  const emailRegex =
    /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

  return emailRegex.test(email)
}

export const companySelection = new CompanySelection()

export function getIsURLValid(url) {
  // https://github.com/vuelidate/vuelidate/blob/next/packages/validators/src/raw/url.js
  const urlRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

  return urlRegex.test(url)
}

export function randomNumberInRange(min, max) {
  // min, max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isValueInRange(
  value,
  min,
  max,
  { inclusiveMin = true, inclusiveMax = true } = {}
) {
  if (!inclusiveMin) {
    const direction = Math.sign(min)
    min = min - direction * 1
  }

  if (!inclusiveMax) {
    const direction = Math.sign(max)
    max = max - direction * 1
  }

  return value >= min && value <= max
}

export function getBrand() {
  const domain = new URL(document.baseURI).hostname
  return domainBrandMap[domain]
}

export function getIsNoLimitPlan(company) {
  return company?.plan.key === 'no_limit'
}

export function getIsFreePlan(company) {
  return isEmpty(company?.plan)
}
