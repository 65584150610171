// Copyright (C) 2023-2024 Skylark Drones

import { mapGetters, mapMutations } from 'vuex'
import { isArray } from 'lodash'

import { MAX_MRU_CAMERAS } from '@/constants'
import SupportedDroneMixin from '../homepage/supported-drones/SupportedDroneMixin'
import { getAllSensorModels } from '@/services/AssetService'

export default {
  mixins: [SupportedDroneMixin],

  computed: {
    ...mapGetters(['currentActiveOrganization', 'user', 'cameraModels']),
    recentCameraModels() {
      return this.currentActiveOrganization.recent_cameras.map(
        recentCameraModelId =>
          this.cameraModels.find(
            cameraModel => cameraModel.id === recentCameraModelId
          )
      )
    }
  },

  methods: {
    ...mapMutations([
      'updateUserAttribute',
      'updateNotificationPayload',
      'updateCameraModels'
    ]),
    async getSensorModels() {
      try {
        const response = await getAllSensorModels()
        const cameraModelObjects = response.data.items
        const cameraModels = cameraModelObjects.map(cameraModel => {
          return {
            id: cameraModel.id,
            model_name: cameraModel.model_name,
            aspect_ratios: cameraModel.aspect_ratios,
            sensor_attributes: cameraModel.sensor_attributes,
            video_aspect_ratios: cameraModel.video_aspect_ratios,
            codecs: cameraModel.codecs,
            resolutions: cameraModel.resolutions,
            ats: cameraModel.ats,
            make: cameraModel.make,
            compatible_with: cameraModel.compatible_with,
            capabilities: cameraModel.capabilities,
            specs: cameraModel.specs,
            picture_url: cameraModel.picture_url
          }
        })
        this.updateCameraModels(cameraModels)
      } catch (e) {
        this.updateNotificationPayload({
          code: 400,
          message: 'Unable to fetch camera models',
          timeout: 500
        })
      }
    },
    getDroneSpeedRange(cameraModel) {
      const defaultValue = {
        min: 1,
        max: 15,
        default: 8
      }

      if (!cameraModel) {
        return defaultValue
      }

      const drone = this.getDroneModel(
        isArray(cameraModel.compatible_with)
          ? cameraModel.compatible_with[0]
          : null
      )

      if (!drone || !drone.specs.drone_speed) {
        return defaultValue
      }

      if (drone.specs.drone_speed.min === 0) {
        drone.specs.drone_speed.min = 0.5
      }

      return drone.specs.drone_speed
    },
    getGimbalPitchRange(cameraModel) {
      if (!cameraModel || !cameraModel.specs.gimbal_pitch)
        return {
          min: -90,
          max: 0,
          default: -45
        }

      return cameraModel.specs.gimbal_pitch
    },
    getMinimumTriggerInterval(cameraModel) {
      return cameraModel ? cameraModel.specs.minimum_trigger_interval : 2
    },
    getIsCameraModelSupportMissionType(cameraModel, missionType) {
      return (
        cameraModel.capabilities.mission_types[missionType].mission_planning ===
        2
      )
    },
    getCameraModelsMenu(missionType) {
      const supportedRecentCameraModels =
        this.getSupportedRecentCameraModels(missionType)
      const supportedCameraModel = this.cameraModels.filter(cameraModel =>
        this.getIsCameraModelSupportMissionType(cameraModel, missionType)
      )
      let menu = [...supportedCameraModel]

      if (this.recentCameraModels.length > 0) {
        menu = [
          { header: 'Recently Used' },
          ...supportedRecentCameraModels,
          { header: 'All Camera Models' },
          ...menu
        ]
      }

      return menu
    },
    getSupportedRecentCameraModels(missionType) {
      return this.recentCameraModels.filter(cameraModel =>
        this.getIsCameraModelSupportMissionType(cameraModel, missionType)
      )
    },
    updateOrgRecentCameraModelsList(cameraModel) {
      const recentCameraList = [
        ...this.currentActiveOrganization.recent_cameras
      ]

      const cameraModelIndex = recentCameraList.findIndex(
        recentCamera => recentCamera === cameraModel
      )

      if (cameraModelIndex !== -1) {
        recentCameraList.splice(cameraModelIndex, 1)
      }

      recentCameraList.unshift(cameraModel)

      const updatedCompaniesList = [...this.user.companies]
      updatedCompaniesList[this.currentActiveOrganization._index] = {
        ...this.currentActiveOrganization,
        recent_cameras: recentCameraList.slice(0, MAX_MRU_CAMERAS)
      }
      this.updateUserAttribute({ companies: updatedCompaniesList })
    }
  }
}
